* {
  box-sizing: border-box;
  line-height: 1.5;
  text-size-adjust: none !important; /* so previews don't have weirdly big text */
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-family-body);
  color: var(--text-primary);
  font-size: 16px; /* 1rem */
  line-height: 1.4;
}

input {
  display: block;
}

p {
  margin: 0 0 .5rem;
}

h3 {
  margin: 0 0 1rem;
}
h4 {
  margin: 0 0 .5rem;
}

li {
  margin: 0 0 .25rem;
}

hr {
  height: 1px;
  background: var(--hairline);
  border: none;
  margin: 1rem 0;
}
