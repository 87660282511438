@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/grayDark.css";
@import "@radix-ui/colors/blueDark.css";
@import "@radix-ui/colors/greenDark.css";
@import "@radix-ui/colors/redDark.css";

@import "open-color/open-color.css";

:root {

  --font-family-display: manrope, -apple-system, BlinkMacSystemFont, sans-serif;
  --font-family-body: -apple-system, BlinkMacSystemFont, sans-serif;
  
  --xxxl: 2.2rem;
  --xxl: 1.8rem;
  --xl: 1.4rem;
  --l: 1.15rem;
  --m: 1rem;
  --s: .9rem;
  --xs: .8rem;

  /*
    Radix colors
     1 - App background
     2 - Subtle background
     3 - UI element background
     4 - Hovered UI element background
     5 - Active / Selected UI element background
     6 - Subtle borders and separators
     7 - UI element border and focus rings
     8 - Hovered UI element border
     9 - Solid backgrounds
    10 - Hovered solid backgrounds
    11 - Low-contrast text
    12 - High-contrast text

    OC colors
    0 - lightest
    9 - darkest
  */

  /* SEMANTIC COLORS */

  /* text */
  --text-primary: var(--oc-gray-9);
  --text-secondary: var(--oc-gray-7);
  --text-tertiary: var(--oc-gray-5);
  /* --text-primary: var(--slate12);
  --text-secondary: var(--slate11);
  --text-tertiary: var(--slate10); */

  /* hairline */
  --hairline: var(--oc-gray-2);
  /* --hairline: var(--slate6); */

  /* forms */
  --input-border: var(--oc-gray-4);
  --input-border-light: var(--oc-gray-3);
  --input-border-dark: var(--oc-gray-5);
  /* --input-border: var(--slate7);
  --input-border-light: var(--slate6);
  --input-border-dark: var(--slate8); */
  
  /* hover */
  --hover: var(--oc-gray-0);
  --outline: var(--oc-blue-2);
  /* --hover: var(--slate4);
  --outline: var(--blue7); */
  
  /* backgrounds */
  --background-item: var(--oc-gray-1);
  --background-item-dark: var(--oc-gray-3);
  /* --background-item: var(--slate2);
  --background-item-dark: var(--slate3); */
  
  /* brand colors */
  --brand-primary: var(--oc-gray-9);
  --brand-primary-light: var(--oc-gray-7);
  --brand-primary-dark: var(--oc-gray-9);
  /* --brand-primary: var(--slate12);
  --brand-primary-light: var(--slate11);
  --brand-primary-dark: var(--slate12); */
  
  /* misc */
  --destructive: var(--oc-red-7);
  /* --destructive: var(--red9); */
  
  /* translucent */
  --shadow: hsla(120, 20%, 20%, .1);
  --shadow-dark: hsla(120, 20%, 20%, .2);
  --shadow-darkest: hsla(120, 20%, 20%, .3);
  --modal-background: hsla(230, 10%, 60%, .5);


  /*
    https://www.joshwcomeau.com/shadow-palette/
    oomph: .25
    crispy: .75
    resolution: .75
    bg color: #f1f3f5;
  */
  --shadow-color: 211deg 5% 68%;
  --shadow-elevation-low:
    0.2px 0.5px 0.5px hsl(var(--shadow-color) / 0.38),
    0.2px 0.7px 0.7px -1.9px hsl(var(--shadow-color) / 0.3),
    0.6px 1.7px 1.7px -3.7px hsl(var(--shadow-color) / 0.21);
  --shadow-elevation-medium:
    0.2px 0.5px 0.5px hsl(var(--shadow-color) / 0.4),
    0.4px 1.1px 1.1px -1.2px hsl(var(--shadow-color) / 0.33),
    1.1px 3.2px 3.2px -2.5px hsl(var(--shadow-color) / 0.27),
    2.9px 8.6px 8.5px -3.7px hsl(var(--shadow-color) / 0.2);
  --shadow-elevation-high:
    0.2px 0.5px 0.5px hsl(var(--shadow-color) / 0.37),
    0.5px 1.4px 1.4px -0.5px hsl(var(--shadow-color) / 0.34),
    0.9px 2.7px 2.7px -1.1px hsl(var(--shadow-color) / 0.31),
    1.7px 5px 5px -1.6px hsl(var(--shadow-color) / 0.28),
    3px 8.9px 8.8px -2.1px hsl(var(--shadow-color) / 0.25),
    5.1px 15.1px 14.9px -2.7px hsl(var(--shadow-color) / 0.22),
    8.2px 24.2px 24px -3.2px hsl(var(--shadow-color) / 0.19),
    12.4px 36.8px 36.4px -3.7px hsl(var(--shadow-color) / 0.16);
}
